<template>
    <div class="app">
        <navbar></navbar>
        <ctrl-enter></ctrl-enter>
        <router-view></router-view>
        <div id="cookie_notification">
                <p>Уважаемый посетитель сайта! В соответствии с требованиями законодательства РФ, предупреждаем вас, 
                   что сайт собирает метаданные пользователя (cookie, данные об IP-адресе и местоположении) для функционирования сайта​. 
                   Если ​вы не согласны с тем, чтобы эти данные обрабатывались, ​вам ​следует покинуть сайт.
                </p>
                <button class="button cookie_accept">Принять</button>
        </div>        
    </div>
</template>

<script>
import Navbar from './components/navbar.vue';
import CtrlEnter from './components/CtrlEnter.vue';

export default {
    name: 'app',
    components: {
        Navbar, CtrlEnter
    },
    methods: {
        checkCookies() {
            let cookieDate = localStorage.getItem('cookieDate');
            let cookieNotification = document.getElementById('cookie_notification');
            let cookieBtn = cookieNotification.querySelector('.cookie_accept');

            // Если записи про кукисы нет или она просрочена на 1 год, то показываем информацию про кукисы
            if( !cookieDate || (+cookieDate + 31536000000) < Date.now() ){
                cookieNotification.classList.add('show');
            }

            // При клике на кнопку, в локальное хранилище записывается текущая дата в системе UNIX
            cookieBtn.addEventListener('click', function(){
                localStorage.setItem( 'cookieDate', Date.now() );
                cookieNotification.classList.remove('show');
            })
        },
    },
    created () {
        this.$store.dispatch('init');
    },
    mounted () {
        this.checkCookies();
    }
}
</script>

<style lang="scss">
    @import "./Styles/common.scss";
    
    #cookie_notification {
        display: none;
        position: fixed;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        padding: 25px;
        width: 95%;
        max-width: 900px;
        background-color: $BackgroundColor;
        border-radius: 10px;
        box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
        z-index: 1000;
    }

    #cookie_notification p {
        margin: 0;
        font-size: 0.7rem;
        text-align: left;
        color: white;
    }

    .cookie_accept {
        padding: 0.5em;
        background-color: $BackgroundColor;
        border: 2px solid white;
        border-radius: 5px;
        color: white;
        margin: 10px 0 0 0;
    }

    #cookie_notification.show {
        display: block;
    }

    @media (min-width: 35em) {
        #cookie_notification p {
            font-size: 0.8rem;
        }
        #cookie_notification {
            left: calc(50% - #{$menuWidth} / 2);
            width: calc(95% - #{$menuWidth});
            transform: translateX(-50%);
        }
    }

    @media (min-width: 75em) {
        #cookie_notification {
            left: calc(50% - #{$menuWidth75em} / 2);
            transform: translateX(-50%);
        }
    }

        
</style>