/*-------------------------------------------------------*/
/*                Modal Ctrl+Enter                       */
/*-------------------------------------------------------*/
<template>
    <div class="c-CtrlEnter" v-if="bShow">
        <form id="i-CtrlEnter--form" @submit.prevent="onSubmit">
            <input type= "hidden" name= "url" v-model="url">
            <div class= "c-Form--group">
                <label for= "selection">Вы выделили:</label>
                <input type= "text" name= "selection" readonly v-model="selection">
            </div>
            <div class= "c-Form--group">
                <label for= "comment">Комментарий:</label>
                <textarea name="comment" v-model="comment" rows="10" placeholder="Введите комментарий к выделенному тексту"></textarea>
            </div>
            <input type="submit" class="c-Form--btn" name="send" value="Отправить">
            <input type="button" class="c-Form--btn" name="cancel" value="Отмена" @click="onCancel">
        </form>
    </div>
</template>    

<script>
    export default {
        props: {
        },
        data() {
            return {
                bShow : false,
                selection : null,
                url : null,
                comment: null,
            }
        },
        mounted() {
            window.addEventListener('keydown', event => {
                if (event.ctrlKey && event.code == "Enter") { 
                    event.preventDefault();
                    this.onCtrlEnter()
                }
            })
        },
        methods: {
            showCover() {
                let div = document.createElement('div');
                div.id = 'cover-div';
                document.body.style.overflowY = 'hidden';
                document.body.append(div);
            },

            hideCover() {
                document.getElementById('cover-div').remove();
                document.body.style.overflowY = '';
            },


            complete(bSend) {
                this.hideCover();
                document.onkeydown = null;
                if (bSend) {
                    let comment   = this.comment;
                    let selection = this.selection;
                    let url       = this.url;
                    axios.post("/api/ctrl_enter", {comment, selection, url})
                        .catch(function (error) {
                            alert(error);
                        });

                } else {
//                    alert("bSend=false");
                }
                this.bShow= false;
            },

            onSubmit() {
                this.complete(true)
            },

            onCancel() {
                this.complete(false)
            },

            showPrompt(selection, url) {
                this.showCover();
                this.comment = '';
                this.url = url;
                this.selection = selection;

                document.onkeydown = e => {
                    if (e.key == 'Escape') complete(false);
                }

                this.bShow= true;
                
                this.$nextTick(function () {
                    let form= document.getElementById('i-CtrlEnter--form');

                    let firstElem = form.elements['comment'];
                    let lastElem = form.elements[form.elements.length - 1];
                    
                    lastElem.onkeydown = e => {
                        if (e.key == 'Tab' && !e.shiftKey) {
                            e.preventDefault();
                            firstElem.focus();
                            return false;
                        }
                    }
                
                    firstElem.onkeydown = e => {
                        if (e.key == 'Tab' && e.shiftKey) {
                            e.preventDefault();
                            lastElem.focus();
                            return false;
                        }
                    }
                
                    form.elements.comment.focus();
                });
             },


            onCtrlEnter() {
                var select = window.getSelection();
                if (select.toString() != "") {
                    var url    = window.location.href;
                    this.showPrompt(select.toString(), url);
                }
            },

        },

    }
</script>

<style lang="scss">
    @import "../Styles/common.scss";

    #i-CtrlEnter--form {
        display: inline-block;
        padding: 5px;
        width: max(200px, 60%);
        border: 1px solid black;
        background: rgb(251, 251, 200);
        vertical-align: middle;
        input {
            width: 90%;
            height: 20px;
            margin-bottom: 10px;
        }
        input:focus {
            color: blue;
        }
        input:hover {
            color: blue;
        }
        textarea {
            width: 90%;
            margin-bottom: 10px;
        }
        textarea:focus {
            color: blue;
        }
        .c-Form--btn {
            width: 80px;
            margin: 10px;
        }
    }
    
    .c-CtrlEnter {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: block;
        width: calc(100% - #{$menuWidth});
        height: 100%;
        text-align: center;
    }
    
    .c-CtrlEnter:before {
        display: inline-block;
        height: 100%;
        content: '';
        vertical-align: middle;
    }

/*
    #i-CtrlEnter--form {
        display: inline-block;
        padding: 5px;
        width: max(200px, 60%);
        border: 1px solid black;
        background: white;
        vertical-align: middle;
        input {
            width: 90%;
            height: 20px;
        }
        input:focus {
            color: blue;
        }
        input:hover {
            color: blue;
        }
        textarea {
            width: 90%;
        }
        textarea:focus {
            color: blue;
        }
        .c-Form--btn {
            width: 80px;
            margin: 5px;
        }
    }
    
    .c-CtrlEnter {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        display: block;
        width: 100%;
        height: 100%;
        text-align: center;
    }
    
    .c-CtrlEnter:before {
        display: inline-block;
        height: 100%;
        content: '';
        vertical-align: middle;
    }
*/
</style>