require('./bootstrap');


import Vue from 'vue'
import VueRouter from 'vue-router';
import VueImg from 'v-img';

import router from './Router/index'
import store from './Store/index';
import App from './App.vue'

Vue.use(VueRouter)


if (window.location.href.indexOf('memory-kommunarka.ru') < 0) {
    console.log('productionTip = false');
    Vue.config.productionTip = false;
}

//---------------------------------------------------------------
// директива v-img
//---------------------------------------------------------------
const vueImgConfig = {
    // Use `alt` attribute as gallery slide title
    altAsTitle: true,
    // Display 'download' button near 'close' that opens source image in new tab
    sourceButton: false,
    // Event listener to open gallery will be applied to <img> element
    openOn: 'click',
    // Show thumbnails for all groups with more than 1 image
    thumbnails: false,
}
Vue.use(VueImg, vueImgConfig);

//---------------------------------------------------------------
// директива v-ratio="{w:100, r:0.5, dh: 20}" 
// где
//    w - ширина в процентах
//    r - отношение высоты к ширине h/w
//   dh - добавка к высоте в пикселях
//---------------------------------------------------------------
Vue.directive('ratio', {
    bind: function (el) {
        // заставляем компонент занять 100% ширины, от которой будем плясать в inserted
        el.style.width= '100%';
    },
    inserted: function (el, binding) {
//        console.log('inserted ratio: ' + el.clientWidth + ':' + el.clientHeight);
        var w= binding.value.w;
        var h= binding.value.r * el.clientWidth * w / 100;// + binding.value.dh;
        el.style.width = Math.round(w) + '%';
        el.style.height= Math.round(h) + 'px';
    },
})

const app = new Vue({
  el: '#app',
  router,
  store,
  components: { App }
});
