import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function createIriRequest(command) {
    return {
      url: `/`,
      data: {'command': command},
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-IOTA-API-Version': '1'
      }
    };
  }

const store = new Vuex.Store({
    state: {
        txt: [],             // различные надписи, меняющиеся в зависимости от языка перевода
        SectionIndex: 0,     // разделы музея
/*        komMenuSh: [],       // краткие названия разделов
        komMenu: [],         // полные названия разделов
        komMenuImgs: [],     // картинки бокового меню
        komMainMenuImgs: [], // картинки меню разделов
        komMenuRoutes: [],   // картинки меню разделов
        komMainMenuImgsAlign: [], // выравнивание картинок меню разделов*/
        groupIndexSelect: -1, // Выбранная плашка соц. групп
        tabDescendants: 0,    // Кнопка на памяти потомков
        tabPersonsSearch: 0,  // Кнопка на поиске по БД
        tabPersonLetter: 0,   // Индекс буквы поиска по фамилии
        tabPersonFio: '',     // Запрос поиска по ФИО
        tabPersonDate: '1-1', // Запрос поиска по дате расстрела
        tabPersonNationality: '', // Запрос поиска по национальности
        tabMemoryBook: 0,
        tabMemoryBookLetter: 0,
        tabHistory: 0,
        loadEvents: 0,
    },
    mutations: {
        INCREMENT(state) {
            state.count++
        },
        INIT(state, data) {
/*            state.komMenuSh      = data.komMenuSh;
            state.komMenu        = data.komMenu;
            state.komMenuImgs    = data.komMenuImgs;
            state.komMenuRoutes  = data.komMenuRoutes;
            state.komMainMenuImgs= data.komMainMenuImgs;
            state.komMainMenuImgsAlign= data.komMainMenuImgsAlign;*/
        },
        SET_SECTION(state, index) {
            state.SectionIndex= index;
        },
        SELECT_GROUP(state, index) {
            state.groupIndexSelect= index;
        },
        SET_TAB_DESCENDANTS(state, index) {
            state.tabDescendants= index;
        },
        SET_TAB_PERSONS_SEARCH(state, index) {
            state.tabPersonsSearch= index;
        },
        SET_TAB_PERSON_LETTER(state, index) {
            state.tabPersonLetter= index;
        },
        SET_TAB_PERSON_FIO(state, index) {
            state.tabPersonFio= index;
        },
        SET_TAB_PERSON_DATE(state, index) {
            state.tabPersonDate= index;
        },
        SET_TAB_PERSON_NATIONALITY(state, index) {
            state.tabPersonNationality= index;
        },
        SET_TAB_MEMORYBOOK(state, index) {
            state.tabMemoryBook= index;
        },
        SET_TAB_MEMORYBOOK_LETTER(state, index) {
            state.tabMemoryBookLetter= index;
        },
        SET_TAB_HISTORY(state, index) {
            state.tabHistory= index;
        },
        SET_LOAD_EVENTS(state, count) {
            state.loadEvents= count;
        },

    },
    actions: {
        init({commit}) {
            axios.get("/api/init")
                .then(response => {
                    commit('INIT', response.data);
                })
                .catch((response) => {
                    console.log('ERROR: /api/init');
                    console.log(response);
                });
        }
    }
})

export default store;
