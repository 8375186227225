export const defaultTitle = 'Место памяти cпецобъект «Коммунарка»';

//===========================================================================================//
//                                          Меню                                             //
//===========================================================================================//

const routes = [
    //-------------------------------------------------------------------------------------------//
    //----------------- Основное меню. Ничего сюда не вставлять!!! ------------------------------//
    //-------------------------------------------------------------------------------------------//
    {
        path: '/',
        component: () => import('../Pages/Home.vue'),
        name: 'home',
        meta: { title: defaultTitle,
                name       : 'Место памяти спецобъект «Коммунарка»',
                short_name : 'Главная страница',
                image      : '/images/home.png',
              }
    },
    {
        path: '/events',
//        component: () => import('../Pages/Recovery.vue'),
        component: () => import('../Pages/Events.vue'),
        name: 'events',
        meta: { title: 'Новости и события - ' + defaultTitle,
                name       : 'Новости и события',
                short_name : 'Новости и события',
                image      : '/images/address.png',
               }
    },
    {
        path: '/actuals',
        component: () => import('../Pages/Actuals.vue'),
        name: 'actuals',
        meta: { title: 'Актуальное - ' + defaultTitle,
                name       : 'Актуальное',
                short_name : 'Актуальное',
                image      : '/images/actual.png',
              }
    },
    {
        path: '/history/:tab?',
        component: () => import('../Pages/History.vue'),
        name: 'history',
        meta: { title: 'История и современность - ' + defaultTitle,
                name       : 'История и современность спецобъекта «Коммунарка»',
                short_name : 'История и современность',
                image      : '/images/hist.png',
              }
    },
    {
        path: '/cpiski_rasstrelyannyh/:tab?',
        component: () => import('../Pages/PersonsSearch.vue'),
        props: true,
        name: 'list',
        meta: { title: 'Списки расстрелянных - ' + defaultTitle,
                name       : 'Cписки расстрелянных',
                short_name : 'Cписки расстрелянных',
                image      : '/images/list.png',
              }
    },
    {
        path: '/stena_pamyati',
        component: () => import('../Pages/Wall.vue'),
        name: 'wall',
        meta: { title: 'Стена памяти - ' + defaultTitle,
                name       : 'Стена памяти',
                short_name : 'Стена памяти',
                image      : '/images/wall.png',
              }
    },
    {
        path: '/socialnye_gruppy_rasstrelyannyh',
        component: () => import('../Pages/GroupMenu.vue'),
        name: 'groups',
        meta: { title: 'Социальные группы расстрелянных - ' + defaultTitle,
                name       : 'Социальные группы расстрелянных',
                short_name : 'Социальные группы',
                image      : '/images/group.png',
              }
    },
    {
        path: '/pamyat_potomkov_intervyu_dokumenty',
        component: () => import('../Pages/Descendants.vue'),
        name: 'descendants',
        meta: { title: 'Память потомков. Интервью, документы - ' + defaultTitle,
                name       : 'Память потомков.</br> Интервью, документы',
                short_name : 'Память потомков',
                image      : '/images/doc.png',
              }
    },
    //////////////////////////// Конец основного меню /////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////
    {
        path: '/event/:slug',
        component: () => import('../Pages/EventPage.vue'),
        props: true,
        name: 'event',
    },
    {
        path: '/actual/:slug',
        component: () => import('../Pages/ActualPage.vue'),
        props: true,
        name: 'actual',
    },
    //----------------- Groups Article ------------------------------
    {
        path: '/groups/:slug',
        component: () => import('../Pages/GroupPage.vue'),
        props: true,
        name: 'groups-article',
    },
    //---------------------- Descendant ------------------------------
    {/********/
        path: '/descendants/:slug',
        component: () => import('../Pages/DescendantItem.vue'),
        props: true,
        name: 'descendants-item',
    },
    //---------------------- Person ----------------------------------
    {
        path: '/person/:id',
        component: () => import('../Pages/Person.vue'),
        props: true,
        name: 'person-id',
        meta: { title: defaultTitle }
    },
    {
        path: '/person/:id/:slug',
        component: () => import('../Pages/Person.vue'),
        props: true,
        name: 'person',
        meta: { title: defaultTitle }
    },
    //---------------------- admin/pages ----------------------------------
    {
        path: '/admin/pages/common/:slug',
        component: () => import('../Pages/GroupPage.vue'),
        props: true,
        name: 'pages-common',
    },
    {
        path: '/admin/pages/biography/:slug',
        component: () => import('../components/admin/ViewPerson.vue'),
        props: true,
        name: 'pages-biography',
    },
    {
        path: '/admin/pages/wall/:slug',
        component: () => import('../components/admin/DraftPage.vue'),
        props: true,
        name: 'pages-wall',
    },
    {
        path: '/admin/pages/events/:slug',
        component: () => import('../components/admin/DraftPage.vue'),
        props: true,
        name: 'pages-events',
    },
    {
        path: '/admin/pages/actual/:slug',
        component: () => import('../components/admin/DraftPage.vue'),
        props: true,
        name: 'pages-actual',
    },
    {
        path: '/admin/pages/document/:slug',
        component: () => import('../Pages/DescendantItem.vue'),
        props: true,
        name: 'pages-document',
    },
    {
        path: '/admin/pages/memorable_dates/:slug',
        component: () => import('../components/admin/DraftPage.vue'),
        props: true,
        name: 'pages-memorable_dates',
    },
    {
        path: '/draft/:pagekey',
        component: () => import('../components/admin/DraftPage.vue'),
        props: true,
        name: 'draft-page',
    },
    {
        path: '/admin/help',
        component: () => import('../Pages/HelpArticle.vue'),
        props: true,
        name: 'admin-help',
    },


    {
        path: '*',
        component: () => import('../Pages/NonExisting.vue'),
        props: true,
        name: 'non-existing',
    },


/*    { 
        path: '/shooting_day', redirect: to => {
            // в функцию в качестве аргумента передаётся маршрут
            // возвращаемым значением должна быть строка или объект пути
//            this.$store.commit('SET_TAB_PERSON_DATE', '11-9');
//            this.$store.commit('SET_TAB_PERSON_DATE', this.day+'-'+this.month);
            return '/cpiski_rasstrelyannyh';
        }
    },*/
  
];

export default routes;

//===========================================================================================//
//                                    Секции сайта                                           //
//===========================================================================================//
// должен соответствовать routes                                                             //
//-------------------------------------------------------------------------------------------//
export const SECTION_HOME           = 0;
export const SECTION_EVENTS         = 1;
export const SECTION_ACTUALS        = 2;
export const SECTION_HISTORY        = 3;
export const SECTION_PERSONS_SEARCH = 4;
export const SECTION_WALL           = 5;
export const SECTION_GROUPS         = 6;
export const SECTION_DESCENDANTS    = 7;
export const SECTIONS_NUMBER        = 8;

export function SetSection(h, $idx) {
    if (h.SectionIndex != $idx) h.$store.commit('SET_SECTION', $idx);
}

export function SetSectionHome(h) {
    SetSection(h, SECTION_HOME);
}

export function SetSectionEvents(h) {
    SetSection(h, SECTION_EVENTS);
}

export function SetSectionActuals(h) {
    SetSection(h, SECTION_ACTUALS);
}

export function SetSectionHistory(h) {
    SetSection(h, SECTION_HISTORY);
}

export function SetSectionPersonsSearch(h) {
    SetSection(h, SECTION_PERSONS_SEARCH);
}
    
export function SetSectionWall(h) {
    SetSection(h, SECTION_WALL);
}

export function SetSectionGroups(h) {
    SetSection(h, SECTION_GROUPS);
}

export function SetSectionDescendants(h) {
    SetSection(h, SECTION_DESCENDANTS);
}

