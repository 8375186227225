import VueRouter from 'vue-router';
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
//        if (to != null) console.log('to:' + to.x + '-' + to.y);
//        if (from != null) console.log('from:'+from.x + '-' + from.y);
        if (to.hash) {
//            console.log('hash:'+to.hash);
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve({
                    selector: to.hash,
                    behavior: 'smooth',
                  });
                }, 500);
              });
        }
        if (savedPosition) {
//            console.log('sp:' + savedPosition.x + '-' + savedPosition.y);
            let position = savedPosition;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(position)
                }, 500)
            })
        } else {
//            console.log('position not saved');
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
});

export default router;
