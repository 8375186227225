<template>
    <nav class="c-NavBar">
        <button class="c-NavBar--toggle" :class= "{ 'is-fixed' : isNavBarFixed }" @click.prevent="isOpen= !isOpen;">
            toggle button
        </button>
        <div class="c-NavBar--dropdown" :class= "{ 'is-open' : isOpen, 'is-fixed' : isNavBarFixed }">
            <ul class="c-NavBar--nav">
                <li v-for="i in SectionsNumber" :key="i" @click="onClick(i)">
                    <router-link :to="getRoute(i)">
                        <template v-if= "SectionIndex == (i-1)">
                            <img :src="getMenu(i).image" :title="getMenu(i).name" :alt="getMenu(i).name"/>
                            <span>{{getMenu(i).short_name}}</span>
                        </template>
                        <template v-else>
                            <img :src="getMenu(i).image" style="filter: contrast(.1)" :title="getMenu(i).name" :alt="getMenu(i).short_name"/>
                            <span style="color: rgb(140,140,140)">{{getMenu(i).short_name}}</span>
                        </template>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import {mapState} from 'vuex';
    import routes from '../Router/routes.js';
    import {SECTIONS_NUMBER} from '../Router/routes.js';

    export default {
        data() {
            return {
                isOpen: false,
                Routes: routes,
                SectionsNumber: SECTIONS_NUMBER,
            }
        },
        computed: {
            ...mapState([
                // прокируем в this.x доступ к store.state.x
/*                'komMenu',
                'komMenuSh',
                'komMenuImgs',*/
                'SectionIndex',
            ]),
            isNavBarFixed: function () {
                // для  всех страниц кроме главной фиксируем меню (SectionIndex= 3)
                return this.SectionIndex != 0;
            },
        },
        methods: {
            onClick: function(index) {
                this.isOpen= false;
                this.$store.commit('SET_SECTION', index-1);
            },
            getRoute: function (index) {
                var str= this.Routes[index-1].path;
                var n= str.indexOf(':');
                return n < 0 ? str : str.slice(0, n);
            },
            getMenu: function (index) {
                return this.Routes[index-1].meta;
            }
        },
    }
</script>

<style lang="scss">
    @import "../Styles/common.scss";

    .c-NavBar {
        position: relative;
        z-index: $z-menu;
    }

    .c-NavBar--toggle {
        position: absolute;
        top: -2px;
        left: calc(100% - #{$menuMiniSize} - 2px);
        border: 0;
        background-color: transparent;
        font-size: 3em;
        width: 1em;
        height: 1em;
        line-height: 0.4;
        text-indent: 5em;
        white-space: nowrap;
        overflow: hidden;
        color: white;
    }
    .c-NavBar--toggle::after {
        position: absolute;
        top: 0.3em;
        right: 0.1em;
        display: block;
        content: "\2261";
        text-indent: 0;
    }

    .c-NavBar--dropdown {
        display: none;
        position: fixed;
        right: 0;
        left: 0;
        top: 2.8em;
        margin: 0;
    }

    .c-NavBar > .is-fixed {
        position: fixed;
    }

    .c-NavBar > .is-open {
        display: block;
    }

    .c-NavBar--nav {
        margin: 0;
        padding-left: 0;
        border: 1px solid #ccc;
        list-style: none;
        background-color: $MenuBgColor;
        color: #fff;
    }

    .c-NavBar--nav > li + li {
        border-top: 3px solid $MenuLineColor;
    }

    .c-NavBar--nav > li > a {
        display: block;
        padding: 0.8em 0.2em;
        color: white;
        text-decoration: none;
        font-size: 0.9em;
//        font-family:'P22 Underground CY Pro Demi';
        font-weight: $WFontHeader;
//        font-weight: 400;
        letter-spacing: 0em;
        span {
            position: relative;
            top: -0.5em;
            left: 0;
        }
    }

    @media (min-width: 35em) {
        .c-NavBar {
            position: fixed;
            top: 0px;
            right: 0;
            width: $menuWidth;
            height: 100vh;
            background-color: $MenuBgColor;
            .is-fixed {
                position: absolute;
            }
        }

        .c-NavBar--toggle {
            display: none;
        }

        .c-NavBar--dropdown {
            display: block;
            position: absolute;
            top: 0;
        }

        .c-NavBar--nav  {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            border: 0px;
            height: calc(100vh - 20px);
            li + li {
                border-top: 3px;
            }

            li {
                 a {
                    display: block;
                    font-size: 0.65em;
                    padding: 10px 5px;
                    text-align: center;
                    
                    span {
                        display: none;
                    }
                    @media (min-height: 600px) {
                        span {
                            display: block;
                        }
                    }

                    img {
                        display: block;
                        padding: 5px 20px;
                    }
                }
             }
        }
    }

    @media (min-width: 75em) {
        .c-NavBar {
            width: $menuWidth75em;
        }
        .c-NavBar--nav  {
            li {
                 a {
                    font-size: 0.8em;
                    img {
                        padding: 5px 30px;
                    }
                 }
            }
        }
    }

</style>